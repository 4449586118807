import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk"; // Import redux-thunk middleware
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage
import cartReducer from "./reducers/cartReducer"; // Your cartReducer

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage, // Specify storage as localStorage
};

// Combine reducers (you can add more reducers as needed)
const rootReducer = combineReducers({
  cart: cartReducer,
  // other reducers can be added here
});

// Persisted reducer to maintain state across sessions
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with thunk middleware and persisted reducer
const store = createStore(
  persistedReducer,
  applyMiddleware(thunk), // Applying redux-thunk middleware
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // Redux DevTools Extension
);

// Create the persistor for rehydrating the store
export const persistor = persistStore(store);

// Export the store as default
export default store;
