// src/redux/actions/cartActions.js
import axios from "axios";

// Action to add an item to the cart
export const addItem = (product, qty = 1) => {
  return {
    type: "ADD_ITEM",
    payload: { ...product, qty },
  };
};

// Action to remove an item from the cart
export const removeItem = (product, qty = 1) => {
  return {
    type: "REMOVE_ITEM",
    payload: { ...product, qty },
  };
};

// Action to clear the cart
export const clearCart = () => {
  return {
    type: "CLEAR_CART",
  };
};

// Action to update the cart
export const updateCart = (newCart) => {
  return {
    type: "UPDATE_CART",
    payload: newCart, // Pass the updated cart data as the payload
  };
};

// Action to update the cart from API
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const updateCartWithAPI = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const updatedCart = await Promise.all(
      state.cart.map(async (item) => {
        const response = await axios.get(`${BASE_URL}/api/products/${item.id}`);
        const product = response.data;
        return { ...item, price: product.price };
      })
    );

    dispatch(updateCart(updatedCart)); // Dispatch updateCart action
  } catch (error) {
    console.error("Error updating cart with API data:", error);
  }
};
