// src/redux/reducers/cartReducer.js
const getInitialCart = () => {
  try {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  } catch (error) {
    console.error("Error parsing cart data from localStorage:", error);
    return [];
  }
};

const initialCart = getInitialCart();

const cartReducer = (state = initialCart, action) => {
  let newState;
  switch (action.type) {
    case "ADD_ITEM":
      const productToAdd = action.payload;
      const existingProduct = state.find((item) => item.id === productToAdd.id);

      if (existingProduct) {
        newState = state.map((item) =>
          item.id === productToAdd.id
            ? { ...item, qty: item.qty + productToAdd.qty }
            : item
        );
      } else {
        newState = [...state, { ...productToAdd, qty: productToAdd.qty }];
      }
      break;

    case "UPDATE_CART":
      newState = action.payload || state;
      break;

    case "REMOVE_ITEM":
      const productToRemove = action.payload;
      const existingProductToRemove = state.find(
        (item) => item.id === productToRemove.id
      );

      if (existingProductToRemove.qty <= 1) {
        newState = state.filter((item) => item.id !== productToRemove.id);
      } else {
        newState = state.map((item) =>
          item.id === productToRemove.id
            ? { ...item, qty: item.qty - productToRemove.qty }
            : item
        );
      }
      break;

    case "CLEAR_CART":
      newState = [];
      break;

    default:
      newState = state;
  }

  // Sync localStorage with updated state
  try {
    localStorage.setItem("cart", JSON.stringify(newState));
  } catch (error) {
    console.error("Error saving cart to localStorage:", error);
  }

  return newState;
};

export default cartReducer;
